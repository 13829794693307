// src/api/accounts.js

import CCSApi from '../services/ccsApi'; // Importa a classe CCSApi

// Cria uma instância do CCSApi sem definir uma função de logout aqui
// Assumindo que a URL base já esteja definida na classe CCSApi
const ccsApi = new CCSApi();

// Função para buscar contas de um usuário
export const fetchAccounts = async (token) => {
  try {
    // Usa a instância de CCSApi para fazer a requisição de busca de contas
    const accountsResponse = await ccsApi.get('/accounts/search', null, token);
    return accountsResponse; // Retorna a resposta da API
  } catch (error) {
    console.error('Erro ao buscar contas:', error);
    throw error; // Propaga o erro para ser tratado no componente que chamou
  }
};


// Função para buscar contas de um usuário
export const searchAccounts = async (userId, token) => {
  try {
    // Usa a instância de CCSApi para fazer a requisição de busca de contas
    const accountsResponse = await ccsApi.post('/accounts/search', { user_id: userId }, token);
    return accountsResponse; // Retorna a resposta da API
  } catch (error) {
    console.error('Erro ao buscar contas:', error);
    throw error; // Propaga o erro para ser tratado no componente que chamou
  }
};
