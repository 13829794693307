import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";

export default function Dashboard() {
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              {/* <li className="breadcrumb-item"><a href="#">/</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
            </ol>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Card className="">
              <Card.Body className="px-3 pb-3">
                <h2>Sistema de Controle de Containers (CCS)</h2>
                <p>
                  Estamos empolgados em anunciar o lançamento do Sistema de Controle de Containers ou CONTAINER CONTROL SYSTEM, 
                  uma solução revolucionária projetada para modernizar, otimizar e transformar o gerenciamento de containers 
                  para negócios de todas as escalas. O CCS é um sistema abrangente que oferece um conjunto completo de funcionalidades 
                  para facilitar a criação, o gerenciamento e a operacionalização de containers em ambientes de TI. Seja para pequenas 
                  startups ou grandes corporações, o CCS promete entregar eficiência operacional, melhorar a segurança e fornecer a 
                  flexibilidade necessária para inovação contínua em desenvolvimento e operações de software.
                </p>
                <h3>Características e Benefícios do CCS:</h3>
                <ul>
                  <li>
                    <strong>Gerenciamento de Ciclo de Vida de Containers:</strong> 
                    O CCS permite aos usuários gerenciar o ciclo de vida completo de seus containers de forma programática, 
                    desde a criação até a destruição, passando pelo início e parada, garantindo uma gestão eficiente dos recursos de TI.
                  </li>
                  <li>
                    <strong>Orquestração e Agendamento:</strong> 
                    Com nossa avançada funcionalidade de orquestração, os usuários podem automatizar o deployment de containers 
                    com base em políticas pré-definidas, garantindo o balanceamento de carga e a alta disponibilidade dos serviços, 
                    otimizando assim a experiência do usuário final e a confiabilidade do sistema.
                  </li>
                  <li>
                    <strong>Gerenciamento de Configuração:</strong> 
                    Facilitamos a replicação de ambientes e a gestão de configurações com ferramentas que permitem versionar e 
                    gerenciar as configurações de containers e aplicações, simplificando a implementação de alterações e a manutenção 
                    de ambientes consistentes.
                  </li>
                  <li>
                    <strong>Backup e Recuperação:</strong> 
                    Nossa solução oferece mecanismos robustos para backups de dados dos containers, assegurando que as estratégias 
                    de recuperação em caso de falhas sejam confiáveis e eficientes, protegendo assim os dados críticos do negócio.
                  </li>
                </ul>
                <h3>Features e seus objetivos:</h3>
                <ul>
                  <li>
                    <strong>Performance e Monitoramento:</strong> 
                    O CCS fornece ferramentas para monitorar o desempenho dos containers em tempo real, coletar logs e oferecer insights 
                    para otimização, garantindo alta performance e disponibilidade dos serviços.
                  </li>
                  <li>
                    <strong>Usabilidade e Acessibilidade:</strong> 
                    Com uma interface intuitiva tanto via CLI quanto GUI, nossa plataforma é acessível para desenvolvedores e operadores 
                    de todos os níveis de habilidade, acompanhada de documentação clara para suportar sua utilização efetiva.
                  </li>
                  <li>
                    <strong>Gestão de Imagens de Containers:</strong> 
                    Integramos um repositório para o armazenamento e gerenciamento de versões de imagens de containers, simplificando 
                    o controle de versão e a distribuição de imagens.
                  </li>
                  <li>
                    <strong>Autenticação e Autorização:</strong> 
                    Implementamos controle de acesso baseado em roles, garantindo que apenas usuários autorizados possam gerenciar 
                    containers e acessar serviços, reforçando a segurança do sistema.
                  </li>
                  <li>
                    <strong>Networking e Comunicação entre Containers:</strong> 
                    O CCS permite configurações de rede avançadas para comunicação segura entre containers e com o mundo externo, 
                    facilitando a integração e a operação de serviços distribuídos.
                  </li>
                  <li>
                    <strong>Logs e Monitoramento:</strong> 
                    Aprofundamos a capacidade de diagnóstico com a coleta, armazenamento e análise de logs dos containers, permitindo 
                    uma rápida identificação e resolução de problemas.
                  </li>
                  <li>
                    <strong>Suporte a Multi-Cloud e On-Premises:</strong> 
                    Nossa solução é projetada para operar em ambientes multi-cloud e on-premises, oferecendo flexibilidade para atender 
                    às necessidades específicas de infraestrutura de cada negócio.
                  </li>
                </ul>
                <p>
                  O Sistema de Controle de Containers (CCS) é mais do que uma ferramenta, é uma solução estratégica que capacita negócios 
                  a abraçarem a inovação, a escalabilidade e a eficiência operacional. Ao escolher o CCS, as empresas estão não apenas 
                  investindo em uma tecnologia de ponta, mas também em uma parceria para o crescimento sustentável e a competitividade 
                  no mercado digital. Convidamos você a explorar as possibilidades com o CCS e a transformar o gerenciamento de containers 
                  em uma vantagem competitiva para o seu negócio.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}
