import React from 'react';
import { Card, Col, Placeholder } from 'react-bootstrap';

const StatCard = ({
  label = '',
  icon = 'ri-default-icon',
  value = '0',
  percent = '0',
  status = 'up',
  backgroundColor = '',
  iconColor = '',
  bottom_line = '',
  isFirst = false,
  loading = false, // Novo prop para controle de loading
}) => {
  const cardStyle = {
    backgroundColor: backgroundColor || (isFirst ? '#f05623' : ''),
  };

  const textColorClass = (backgroundColor || isFirst) ? 'text-white' : '';

  const iconStyle = {
    color: iconColor || (textColorClass === 'text-white' ? '#fff' : '#000'),
  };

  return (
    <Col xs={isFirst ? 12 : 4} xl={3}>
      <Card className={`card-one`} style={cardStyle}>
        <Card.Body className={textColorClass}>
          {loading ? (
            <>
              <Placeholder as="label" animation="glow" className="fs-sm fw-medium mb-1">
                <Placeholder xs={6} />
              </Placeholder>
              <h3 className="card-value mb-1">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={4} /> <Placeholder xs={6} />
                </Placeholder>
              </h3>
              <Placeholder as="small" animation="glow">
                <Placeholder xs={8} />
              </Placeholder>
            </>
          ) : (
            <>
              <Card.Title as="label" className="fs-sm fw-medium mb-1">
                {label}
              </Card.Title>
              <h3 className="card-value mb-1">
                <i className={icon} style={iconStyle}></i> {value}
              </h3>
              <small style={iconStyle}>
                {bottom_line}
              </small>
            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default StatCard;
