import React from "react";
import { Col, Row, Container } from "react-bootstrap";

const SentTotal = ({ startDate, endDate, TotalContacts }) => {
  const formattedStartDate = startDate instanceof Date ? startDate.toLocaleDateString('pt-BR') : startDate;
  const formattedEndDate = endDate instanceof Date ? endDate.toLocaleDateString('pt-BR') : endDate;

  return (
    <Container fluid className="p-0 mt-3 mb-5">
      <Row className="align-items-stretch">
        {/* Primeira coluna: Ajustada para 50% */}
        <Col xs={6} md={6} className="text-end p-4 justify-content-end">
          <h2 className="text-orange display-7 mt-3">
            No período de <br />{formattedStartDate} até {formattedEndDate} <br /> enviamos 
          </h2>
        </Col>

        {/* Segunda coluna: Ajustada para 50% */}
        <Col xs={6} md={6} className="text-start bg-orange d-flex justify-content-start align-items-center p-4">
          <div>
            <h3 className="display-1 text-white">{TotalContacts ? TotalContacts : '---'}</h3>
            <h2 className="text-white">emails</h2>
          </div>
        </Col>
      </Row>
      
      {/* Texto do parágrafo movido para fora do Row */}
      <p className="small mt-2 text-center">
        * Total de emails enviados somando os emails de boas vindas, recuperação de depósitos, e-mails sazonais e de promoções da casa.
        ** O número de envios pode não representar total de emails entregues, devido a falhas nas causados por endereços falhos, problemas de reputação de ips e ou domínios, ou questionamento e bloqueios causados  pelos serviços de e-mail a respeito do conteúdo dos emails.

      </p>
    </Container>
  );
};

export default SentTotal;
