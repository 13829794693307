import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import img1 from '../../assets/img/img1.jpg';

const profileItems = [
    {
    icon: 'ri-team-line',
    text: 'Contatos',
    label: '365'
  },
  {
    icon: 'ri-medal-2-line',
    text: 'Envios',
    label: '670'
  },
    {
    icon: 'ri-suitcase-line',
    text: 'Abertura',
    label: '25%'
  },
    {
    icon: 'ri-suitcase-line',
    text: 'Cliques',
    label: '15%'
  },
  {
    icon: 'ri-suitcase-line',
    text: 'Recuperados',
    label: '200'
  }
];

const ApplicationDescription = () => {
  return (
    <div>
      <div className="media-profile mb-2">
        <div className="media-img mb-3 mb-sm-0">
          <img src={img1} className="img-fluid" alt="Profile" />
        </div>
        <div className="media-body">
          <h5 className="media-name">mkt.boombets.com</h5>
          <p className="d-flex gap-2 mb-4"><i className="ri-map-pin-line"></i> https://boombets.com</p>

          <Row className="row-cols-sm-auto g-6 g-md-6 g-xl-6 g-xxl-6">
          {profileItems.map((profileItem, index) => (
          <Col key={index} xs={12} md={4}>
            <div className="profile-item ">
              <i className={profileItem.icon}></i>
              <div className="profile-item-body">
                <p>{profileItem.text}</p>
                <span>{profileItem.label}</span>
              </div>
            </div>
          </Col>
        ))}
      </Row>
        </div>
      </div>
        <p className="mb-0">
            BoomBets, clientes desde <span>05/2024</span>, enviando em média <span>XXX</span> novos leads e <span>XXX</span>
            emails diários. Este cliente está em contrato ATIVO sob o regime de participação nos resultados... <Link to="">Read more</Link>
        </p>
    </div>
  );
};

export default ApplicationDescription;
