import React from "react";
import { Grid } from "gridjs-react";
// import "gridjs/dist/theme/mermaid.css";

const SentEmailbyId = ({ envios_por_id = [] }) => {
  // Preparar os dados para o Grid.js
  const gridData = envios_por_id.length > 0 
    ? envios_por_id.map((item) => [
        item.id,
        item.nome,
        item.enviados,
        item.lidos,
        item.clicados !== null ? item.clicados : '0',
        item.media_lidos || '0%',
        item.media_cliques || '0%'
      ])
    : [["Nenhum dado disponível", "", "", "", "", "", ""]];

  return (
    <div className="my-4">
      <h4 className="text-start">Envios por ID</h4>
      <Grid
        data={gridData}
        columns={[
          { name: "ID", width: '5%' },
          { name: "Nome", width: '45%' },
          { name: "Enviados", width: '10%' },
          { name: "Lidos", width: '10%' },
          { name: "Clicados", width: '10%' },
          { name: "Média Lidos", width: '10%' },
          { name: "Média Cliques", width: '10%' },
        ]}
        // search={true}
        // pagination={{
        //   enabled: true,
        //   limit: 10, // Número de registros por página
        // }}
        sort={true}
        resizable={true}
        className={{
          table: 'table table-bordered mb-0',
          thead: 'table-header',
          tbody: 'table-body',
          th: 'table-header-cell',
          td: 'table-data-cell'
        }}
        style={{
          container: {
            padding: "16px",
            backgroundColor: "white",
          },
          table: {
            width: "100%",
            border: "1px solid #ccc",
          },
        }}
      />
    </div>
  );
};

export default SentEmailbyId;
