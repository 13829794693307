async function generateSHA256Hash(email) {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join('');
}

export async function GetGravatar(size = 64) {
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  if (!userProfile) {
    return { type: 'default' };
  }

  const { avatarDef, email, avatarImg } = userProfile;

  if (avatarDef === 'gravatar' && email) {
    const hashedEmail = await generateSHA256Hash(email);
    const gravatarUrl = `https://www.gravatar.com/avatar/${hashedEmail}?s=${size}`;
    return { type: 'gravatar', url: gravatarUrl };
  }

  if (avatarDef === 'local' && avatarImg) {
    return { type: 'local', url: avatarImg };
  }

  return { type: 'default' };
}
