// src/services/ccsApi.js

class CCSApi {
  constructor(logoutFunction) {
    // Define a URL da API diretamente na classe
    this.apiUrl = 'https://api.leadhunterexpert.com';
    this.logoutFunction = logoutFunction;
  }

  async request(endpoint, method, data = null, token = null) {
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `${token}`;
    }

    const config = {
      method,
      headers,
      body: data ? JSON.stringify(data) : null,
    };

    try {
      const response = await fetch(`${this.apiUrl}${endpoint}`, config);
      const contentType = response.headers.get('Content-Type');

      let responseBody = null;
      if (contentType && contentType.includes('application/json')) {
        responseBody = await response.json();
      } else {
        responseBody = await response.text();
      }

      if (!response.ok) {
        if (response.status === 401 && responseBody.message === 'Token expirado') {
          this.logoutFunction();
        }
        throw new Error(responseBody.message || 'Erro ao fazer a requisição');
      }

      return responseBody;
    } catch (error) {
      throw new Error(error.message || 'Erro desconhecido');
    }
  }

  get(endpoint, token = null) {
    return this.request(endpoint, 'GET', null, token);
  }

  post(endpoint, data, token = null) {
    return this.request(endpoint, 'POST', data, token);
  }

  put(endpoint, data, token = null) {
    return this.request(endpoint, 'PUT', data, token);
  }

  patch(endpoint, data, token = null) {
    return this.request(endpoint, 'PATCH', data, token);
  }

  delete(endpoint, token = null) {
    return this.request(endpoint, 'DELETE', null, token);
  }
}

export default CCSApi;
