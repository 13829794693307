import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

const SentLastDays = ({
  totalEnviados = 0,
  totalAbertos = 0,
  totalNaoAbertos = 0,
  mediaAbertura = 0,
  graficoDados = []
}) => {

  // Configurações do gráfico
  const chartOptions = {
    chart: {
      type: 'area',
      height: 350,
      background: '#f05623',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      colors: ['#ffffff'],
      width: 2,
    },
    fill: {
      colors: ['#ffffff'],
      opacity: 0.7,
    },
    grid: {
      borderColor: '#ffffff',
    },
    xaxis: {
      labels: {
        style: {
          colors: '#ffffff',
        },
      },
      axisTicks: {
        color: '#ffffff',
      },
      axisBorder: {
        show: true,
        color: '#ffffff',
      },
      categories: graficoDados.map(item => item.hora)
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff',
        },
      },
    },
    title: {
      text: 'Horários mais movimentados',
      align: 'left',
      style: {
        fontSize: '24px',
        color: '#ffffff',
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  const chartSeries = [
    {
      name: "Aberturas",
      data: graficoDados.map(item => item.cadastros_por_hora)
    }
  ];

  return (
    <Container fluid className="p-5" style={{ backgroundColor: "#fff" }}>
      <Row>
        {/* Primeira coluna */}
        <Col xs={12} md={4} className="p-4">
          <h5 className="fw-bold">Para entender melhor:</h5>
          <h2 className="text-dark">Dos {totalEnviados.toLocaleString()} emails enviados neste período.</h2>
          <Row className="mt-4">
            <h5 className="fw-bold">Emails abertos:</h5>
            <h2 className="text-orange display-4">{totalAbertos.toLocaleString()}</h2>
          </Row>
          <Row className="mt-4">
            <h5 className="fw-bold">Emails não abertos:</h5>
            <h2 className="text-orange display-4">{totalNaoAbertos.toLocaleString()}</h2>
          </Row>
          <Row className="mt-4">
            <h5 className="fw-bold">Média de abertura:</h5>
            <h2 className="text-orange display-4">{mediaAbertura.toFixed(2)}%</h2>
          </Row>
        </Col>

        {/* Terceira coluna: Gráfico */}
        <Col xs={12} md={8} className="p-4">
          <h4 className="text-dark">Horários das leituras</h4>
          <div style={{ backgroundColor: "#f05623", padding: "20px" }}>
            <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={350} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SentLastDays;
