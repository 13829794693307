import { Col, Row, Container, Spinner } from "react-bootstrap";
import ReactApexChart from 'react-apexcharts';

const GrowDayWeek = ({ contatos_dia_semana, loading }) => {
  // Verifica se os dados foram recebidos antes de prosseguir
  if (loading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center bg-orange" style={{ height: 350 }}>
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      </Container> 
    );
  }

  // Verifica se há dados para exibir
  if (!contatos_dia_semana || contatos_dia_semana.length === 0) {
    return (
      <Container fluid className="p-4 bg-orange text-center">
        <p>Nenhum dado disponível para exibir.</p>
      </Container>
    );
  }

  // Mapeia os dados da API para o formato necessário para o gráfico
  const labels = contatos_dia_semana.map(item => item.dia_da_semana);
  const series = contatos_dia_semana.map(item => item.cadastros_por_dia);

  // Calcula os percentuais de cada dia para exibir na lista
  const totalCadastros = series.reduce((acc, val) => acc + val, 0);
  const percentualPorDia = series.map(value => ((value / totalCadastros) * 100).toFixed(1));

const optionDonut = {
  chart: {
    type: 'donut',
    height: '100%', // Ocupa 100% da altura
    width: '100%',  // Ocupa 100% da largura
    parentHeightOffset: 0, // Remove qualquer margem do contêiner pai
    parentWidthOffset: 0,  // Remove qualquer margem do contêiner pai
  },
  labels: labels,
  colors: ['#f4c542', '#f7e7a2', '#f0d77b', '#eeb652', '#d48938', '#cb7421', '#a14c12'], // Cores para cada fatia
  legend: {
    show: false, // Esconde a legenda padrão do gráfico
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '18px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      colors: ['#000'], // Define o texto como preto
    },
    dropShadow: {
      enabled: false, // Remove a sombra dos valores
    },
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
        },
      },
    },
  },
  stroke: {
    show: false, // Remove o contorno branco do gráfico
  },
  fill: {
    opacity: 1, // Garante que as cores sejam sólidas
  },
  responsive: [
    {
      breakpoint: 40,
      options: {
        chart: {
          width: 300,
        },
      },
    },
  ],
};



  return (
    <Container fluid className="p-0 bg-orange">
      <Row className="align-items-center justify-content-between">
        {/* Coluna da esquerda */}
        <Col md={4} className="text-left p-4">
          <h3 className="text-light">Como foi o mês:</h3>
          <ul className="lead text-light">
            {contatos_dia_semana.map((item, index) => (
              <li key={index}>{item.dia_da_semana} - {percentualPorDia[index]}%</li>
            ))}
          </ul>
          <p className="text-muted small">
            * Percentual calculado a partir do dia da semana em que o lead é adicionado ao nosso sistema.
          </p>
        </Col>

        {/* Coluna da direita com o gráfico */}
        <Col md={8} className="text-end p-4">
          <ReactApexChart series={series} options={optionDonut} type="donut" />
        </Col>
      </Row>
    </Container>
  );
};

export default GrowDayWeek;
