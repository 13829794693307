import React, { useState } from 'react';
import { Card, Table, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const fetchData = async (startDate, endDate) => {
  // Simulação de uma chamada de API para buscar os dados com base nas datas fornecidas.
  // Substitua esta função pela chamada real da API.
  console.log('Fetching data for dates:', startDate, endDate);
  return [
    {
      date: '2024-05-01',
      email_id: 1,
      email_name: 'Campaign 1',
      total_send: 5000,
      total_read: 2500,
      hits_count: 1000,
    },
    {
      date: '2024-05-02',
      email_id: 2,
      email_name: 'Campaign 2',
      total_send: 4000,
      total_read: 2000,
      hits_count: 900,
    },
    // Adicione mais dados simulados conforme necessário.
  ];
};

const ReportEmailsGlobal = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState([]);

  const handleFilter = async () => {
    const result = await fetchData(startDate, endDate);
    setData(result);
  };

  const handleForceRefresh = async () => {
    setStartDate(new Date());
    setEndDate(new Date());
    const result = await fetchData(new Date(), new Date());
    setData(result);
  };

  return (
    <Card className="card-one mt-3">
      <Card.Body>
        <Row className="mb-3">
          <Col md={4}>
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
            />
          </Col>
          <Col md={4}>
            <ReactDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
            />
          </Col>
          <Col md={4} className="d-flex align-items-center">
            <Button onClick={handleFilter} className="btn btn-primary mr-2">
              Filtrar
            </Button>
            <Button onClick={handleForceRefresh} className="btn btn-secondary">
              Force Refresh
            </Button>
          </Col>
        </Row>
        <Table className="table-four table-bordered">
          <thead>
            <tr>
              <th style={{ width: '5%' }}>Email ID</th>
              <th style={{ width: '50%' }}>Email Name</th>
              <th style={{ width: '15%' }}>Total Send</th>
              <th style={{ width: '15%' }}>Total Read</th>
              <th style={{ width: '15%' }}>Hits Count</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.email_id}</td>
                <td><Link to="#">{item.email_name}</Link></td>
                <td>{item.total_send}</td>
                <td>{item.total_read}</td>
                <td>{item.hits_count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ReportEmailsGlobal;
