import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { applicationData } from '../api/customers';
import AuthContext from "../contexts/AuthContext"; 

import ApplicationHandler from "../components/ApplicationHandler/ApplicationHandler";
import DateRangePicker from "../components/DateRangePicker/DateRangePicker";
import ResponseDisplay from "../components/ResponseDisplay/ResponseDisplay"; 


import GrowthTotal from "../components/ReportsMautic/GrowthTotal"; 
import GrouthMonths from "../components/ReportsMautic/GrouthMonths"; 
import StatCards from "../components/ReportsMautic/StatCards"; 
import GrowthPerHour from "../components/ReportsMautic/GrowthPerHour"; 
import GrowDayWeek from "../components/ReportsMautic/GrowDayWeek"; 
import EmailQuarter from "../components/ReportsMautic/EmailQuarter"; 
import ContactsEmail from "../components/ReportsMautic/ContactsEmail"; 
import SentTotal from "../components/ReportsMautic/SentTotal"; 
import SentLastDays from "../components/ReportsMautic/SentLastDays"; 
import SentDayWeek from "../components/ReportsMautic/SentDayWeek"; 
import SentEmailbyId from "../components/ReportsMautic/SentEmailbyId"; 


export default function Management() {
  const { token } = useContext(AuthContext);
  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const [responseText, setResponseText] = useState(null);
  const [loading, setLoading] = useState(true);

  // Função para extrair datas da URL ou definir valores padrão
  const getInitialDates = () => {
    const params = new URLSearchParams(window.location.search);
    const startDateParam = params.get('startDate');
    const endDateParam = params.get('endDate');

    const parseDateWithoutTimezone = (dateStr) => {
      const [year, month, day] = dateStr.split('-');
      return new Date(Number(year), Number(month) - 1, Number(day));
    };

    let parsedStartDate = null;
    let parsedEndDate = null;

    if (startDateParam) {
      parsedStartDate = parseDateWithoutTimezone(startDateParam);
    }
    if (endDateParam) {
      parsedEndDate = parseDateWithoutTimezone(endDateParam);
    }

    if (
      parsedStartDate &&
      !isNaN(parsedStartDate) &&
      parsedEndDate &&
      !isNaN(parsedEndDate)
    ) {
      return { startDate: parsedStartDate, endDate: parsedEndDate };
    } else {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - 7);
      return { startDate, endDate };
    }
  };

  // Inicializa os estados com as datas
  const initialDates = getInitialDates();
  const [startDate, setStartDate] = useState(initialDates.startDate);
  const [endDate, setEndDate] = useState(initialDates.endDate);

  // Função para buscar dados da API
  const fetchData = async () => {
    setLoading(true);
    console.log('Chamando fetchData');
    if (!startDate || !endDate) {
      console.error('Por favor, selecione ambas as datas de início e fim.');
      return;
    }

    const data = {
      from_day: startDate.toISOString().split('T')[0],
      to_day: endDate.toISOString().split('T')[0],
      timezone_offset: '-3',
      filters: [],
    };

    try {
      const response = await applicationData(token, data);
      setResponseText(response);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      setLoading(false);
    }
  };

  // Hook para buscar os dados da API sempre que o token, startDate ou endDate mudarem
  useEffect(() => {
    if (token && startDate && endDate) {
      setLoading(true);
      fetchData();
    }
  }, [token, startDate, endDate]);

  const handleDateChange = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleFilter = () => {
    fetchData();
  };

  const cardsData = [
    {
      label: responseText?.nick || '--',
      icon: 'ri-shopping-bag-3-line',
      value: responseText?.domain || 'Domínio não disponível',
      status: 'up',
      backgroundColor: '#f05623', 
      iconColor: '#ffffff', 
      bottom_line: 'teste',
    },
    {
      label: 'Total de contatos',
      icon: 'ri-briefcase-4-line',
      value: responseText?.report_result?.contatos_total || '--',
      bottom_line: responseText?.report_result?.contatos_last_7_days
        ? `Últimos 7 dias: ${responseText.report_result.contatos_last_7_days}`
        : 'Dados dos últimos 7 dias não disponíveis',
      status: 'up',
    },
    {
      label: 'Total de envios',
      icon: 'ri-inbox-line',
      value: responseText?.report_result?.envios_total || '--',
      bottom_line: responseText?.report_result?.envios_per_day?.average_emails_per_day
        ? `Média de envios por dia: ${responseText.report_result.envios_per_day.average_emails_per_day}`
        : 'Dados dos últimos 7 dias não disponíveis',
    },
    {
      label: 'Média de abertura',
      icon: 'ri-bar-chart-box-line',
      value: `${responseText?.report_result?.abertura_total || '--'} emails`,
      bottom_line: responseText?.report_result?.abertura_total_media
        ? `Média abertura: ${responseText.report_result.abertura_total_media}%`
        : 'Dados dos últimos 7 dias não disponíveis',
      percent: '1.2',
      status: 'up',
    },
  ];

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Application</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Mautic</li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-4 mt-md-0 fs-sm">
            <DateRangePicker
              onDateChange={handleDateChange}
              onFilter={handleFilter}
              startDate={startDate}
              endDate={endDate}
              loading={loading}
            />
          </div>
        </div>

        <Row className="g-3">
          <StatCards cardsData={cardsData} />
        </Row>

        {/* Conteúdo adicional e títulos */}
        {/* <h3 className="mt-4">- Leads Gerados (total) - GrowthTotal</h3> */}
          <Row className="g-3 mt-1">
            <GrowthTotal
              startDate={startDate}
              endDate={endDate}
              TotalContacts={responseText?.report_result?.contatos_no_periodo} 
              loading={loading}
            />
          </Row>
          <Row className="g-3 mt-1">
            <GrouthMonths 
              contatos_hoje={responseText?.report_result?.contatos_hoje}
              contatos_ontem={responseText?.report_result?.contatos_ontem}
              contatos_15_dias={responseText?.report_result?.contatos_15_dias}
              contatos_meses={responseText?.report_result?.contatos_meses}  
              contatos_quinzena={responseText?.report_result?.contatos_quinzena}
              startDate={startDate}
              endDate={endDate} 
              loading={loading}
            />
          </Row>
          
          <Row className="g-3 mt-1">
            {/* Primeira Coluna */}
            <Col md={12} lg={6}>
                <GrowDayWeek 
                    contatos_dia_semana={responseText?.report_result?.contatos_dia_semana} 
                    loading={loading} 
                />
            </Col>

            {/* Segunda Coluna */}
            <Col md={12} lg={6}>
               <GrowthPerHour 
                    contatos_hora={responseText?.report_result?.contatos_hora} 
                    loading={loading} 
                />
            </Col>
          </Row>
          <h3 className="mt-4">ContactsEmail</h3>
          <Row className="g-3 mt-1">
            <ContactsEmail 
                contatos_dominio={responseText?.report_result?.contatos_dominio} 
                contatos_mais_abrem={responseText?.report_result?.contatos_mais_abrem} 
                envios_dominio={responseText?.report_result?.envios_dominio} 
            />
          </Row>

          <Row className="g-3 mt-1">
            <h3 className="mt-4">SentTotal</h3>
            <SentTotal 
              startDate={startDate}
              endDate={endDate}
              TotalContacts={responseText?.report_result?.envios_total} 
            />
          </Row>


          <Row className="g-3 mt-1">
            {/* <h3 className="mt-4">SentLastDays</h3> */}
            <SentLastDays
              totalEnviados={responseText?.report_result?.envios_total || 0}
              totalAbertos={parseInt(responseText?.report_result?.envios_resumo?.total_lidos) || 0}
              totalNaoAbertos={
                (parseInt(responseText?.report_result?.envios_total) - parseInt(responseText?.report_result?.envios_resumo?.total_lidos)) || 0
              }
              mediaAbertura={
                responseText?.report_result?.envios_resumo?.media_lidos
                  ? parseFloat(responseText?.report_result?.envios_resumo?.media_lidos.replace(",", "."))
                  : 0
              }
              graficoDados={responseText?.report_result?.contatos_hora || []}
            />
          </Row>

          <Row className="g-3 mt-1">
            {/* Primeira Coluna */}
            <Col md={12} lg={6}>
            {/* <h3 className="mt-4">SentDayWeek</h3> */}
              <SentDayWeek envios_semana={responseText?.report_result?.envios_semana || []} />
            </Col>
            
            {/* Segunda Coluna */}
            <Col md={12} lg={6}>
            {/* <h3 className="mt-4">EmailQuarter</h3> */}
              <EmailQuarter
                envios_periodo={responseText?.report_result?.envios_periodo} 
                loading={loading} 
               />
            </Col>
          </Row>        

          {/* <h3 className="mt-4">- Planilha cenário</h3> */}
          <Row className="g-3 mt-1">
              <SentEmailbyId envios_por_id={responseText?.report_result?.envios_por_id || []} />
          </Row>
          

          {/* <h3 className="mt-4">- Postmaster</h3> */}

        {/* 
        {responseText && (
          <Row className="g-3 mt-4">
            <Col>
              <ResponseDisplay response={responseText} />
            </Col>
          </Row>
        )} */}

        <Footer />
      </div>
    </React.Fragment>
  );
}
