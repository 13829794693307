// src/api/customer.js

import CCSApi from '../services/ccsApi'; // Importa a classe CCSApi

// Define uma instância da classe CCSApi
const ccsApi = new CCSApi();

// Função de logout completa que limpa todos os dados de sessão
export const clogout = () => {
  console.log('Executando logout e limpando dados de sessão...');
  
  // Lista de chaves a serem removidas do localStorage
  const itemsToRemove = [
    'token',
    'userProfile',
    'selectedAccountId',
    'application_data',
    // Adicione mais itens à lista, se necessário
  ];

  // Laço para remover todos os itens do localStorage
  itemsToRemove.forEach(item => {
    localStorage.removeItem(item);
  });

//   // Remover todos os itens que começam com "report_"
//   for (let i = 0; i < localStorage.length; i++) {
//     const key = localStorage.key(i);
//     if (key.startsWith('report_')) {
//       localStorage.removeItem(key);
//       i--; // Ajusta o índice após remover um item, já que o localStorage.length muda
//     }
//   }
};


// Função para realizar login
export const clogin = async (email, password) => {
  try {
    const response = await ccsApi.post('/customers/login', { email, password });
    if (response.token || response.access_token) {
      // localStorage.setItem('token', response.token); // Armazena o token de autenticação
      // localStorage.setItem('access_token', response.access_token);
      return response;
    } else {
      throw new Error('Token not received');
    }
  } catch (error) {
    console.error('Erro durante o login:', error);
    throw error;
  }
};

// Função para verificar a validade do token e retornar a resposta
export const applicationData = async (token, data) => {
  try {
    // Realiza a requisição POST com os dados e token
    console.log(token)
    const response = await ccsApi.post('/customers/creport', data, token);

    console.log({response} ); // Exibe a resposta da API

    // Retorna o objeto de resposta diretamente
    return response;
  } catch (error) {
    console.error('Erro durante a verificação do token:', error);
    throw new Error(error.message || 'Erro desconhecido durante a verificação do token');
  }
};

// Função para verificar se o token é válido
export const ccheckToken = async (token) => {
  try {
    // Realiza a requisição para validar o token
    const response = await ccsApi.get('/customers/check', token);

    // Exibe a resposta no console para fins de debug
    console.log('Resposta do check:', response);

    // Verifica se a resposta possui as propriedades esperadas
    if (response && response.valid && response.customer_id) {
      return true; // Retorna true se o token é considerado válido
    } else {
      return false; // Retorna false se alguma propriedade estiver faltando ou for inválida
    }
  } catch (error) {
    console.error('Erro durante a verificação do token:', error);
    return false; // Retorna false se ocorrer um erro
  }
};


