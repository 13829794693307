// src/pages/Signin.js

import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap";
import AuthContext from "../contexts/AuthContext"; // Ajuste o caminho conforme necessário

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isAuthenticated, login } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      console.log('Status sim = ' + isAuthenticated)
      console.log('AUTENTICADO');
      navigate('/');
    } else if (isAuthenticated === null || isAuthenticated === false) {
      console.log('Status não = ' + isAuthenticated)
      console.log('NAO AUTENTICADO');
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Attempting login with email:', email);
    const success = await login(email, password);
    if (success) {
      console.log('Login successful, navigating to dashboard');
      // navigate("/dashboard");
    } else {
      console.log('Login failed');
      setError('Login failed. Please check your email and password.');
    }
  };


  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">LeadHunterExpert</Link>
          <Card.Title>Relatórios (Reports)</Card.Title>
          <Card.Text>Realise seu login para acessar os relatórios.</Card.Text>
        </Card.Header>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>} {/* Exibe mensagem de erro */}
          <Form onSubmit={handleSubmit}> {/* Substituímos o método por onSubmit */}
            <div className="mb-4">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entre com seu e-mail cadastrado."
                value={email}
                onChange={(e) => setEmail(e.target.value)}  // Atualiza estado do email
              />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Senha <Link to="">Esqueceu a senha?</Link>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Entre com sua senha."
                value={password}
                onChange={(e) => setPassword(e.target.value)}  // Atualiza estado da senha
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Sign In</Button>

           </Form>
        </Card.Body>
        <Card.Footer>
          {/* Don't have an account? <Link to="/pages/signup">Create an Account</Link> */}
        </Card.Footer>
      </Card>
    </div>
  );
}
