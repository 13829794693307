import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import ReportEmailsGlobal from "../components/ReportEmailsGlobal/ReportEmailsGlobal";
import ApplicationHandler from "../components/ApplicationHandler/ApplicationHandler";

export default function FinanceMonitoring() {


  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);


  // Simulação de um perfil logado
const getLoggedInProfile = () => {
  // Simule um perfil logado retornando um cliente
  return 'client1'; // Retorne null se ninguém estiver logado
};

  const [selectedApplication, setSelectedApplication] = useState('');
  const client = getLoggedInProfile();

  const handleApplicationChange = (application) => {
    setSelectedApplication(application);
    console.log('Aplicação selecionada:', application);
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Application</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Mautic</li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <ApplicationHandler client={client} onApplicationChange={handleApplicationChange} />
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-share-line fs-18 lh-1"></i>Share
            </Button>
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>

          </div>
        </div>

        <Row className="g-3">
          <Col xl="6">
            <Card className="">
              <Card.Body className="px-3 pb-3">

              </Card.Body>
            </Card>
          </Col>

        <ReportEmailsGlobal />

        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}
