import React from 'react';
import { Container, Row, Spinner } from "react-bootstrap";
import EmailQuarterBlock from './EmailQuarterBlock'; // Importe o componente individual

const EmailQuarter = ({ envios_periodo = [], loading }) => {
  // Verifica se os dados estão carregando
  if (loading) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center bg-orange" style={{ height: 350 }}>
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      </Container>
    );
  }

  // Verifica se há dados para exibir
  if (!envios_periodo || envios_periodo.length === 0) {
    return (
      <Container fluid className="p-4 bg-orange text-center">
        <p>Nenhum dado disponível para exibir.</p>
      </Container>
    );
  }

  // Define os intervalos de horário correspondentes a cada período
  const timeRanges = {
    "Madrugada": "00h até 05h",
    "Manhã": "06h até 11h",
    "Tarde": "12h até 18h",
    "Noite": "19h até 23h",
  };

  return (
    <Container fluid className="bg-orange text-center py-5">
      <Row>
        {/* Mapeia os dados da API e envia via props para o componente individual */}
        {envios_periodo.map((item, index) => (
          <EmailQuarterBlock
            key={index}
            period={item.periodo}
            timeRange={timeRanges[item.periodo] || ''}
            percentage={parseFloat(item.percentual_abertos).toFixed(1)}
            emailsOpened={`${item.total_abertos.toLocaleString()} e-mail${item.total_abertos > 1 ? 's' : ''} abertos`}
          />
        ))}
      </Row>
    </Container>
  );
};

export default EmailQuarter;
