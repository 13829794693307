import React, { useState } from "react";
import { Button, Col, Row, Table, Container, Spinner } from "react-bootstrap";

const GrouthMonths = ({ contatos_hoje, contatos_ontem, contatos_15_dias, contatos_meses = [], contatos_quinzena = [], startDate, endDate, loading }) => {
    const formattedStartDate = startDate instanceof Date ? startDate.toLocaleDateString('pt-BR') : startDate;
    const formattedEndDate = endDate instanceof Date ? endDate.toLocaleDateString('pt-BR') : endDate;

    // Configurações de paginação
    const itemsPerPage = 6; // Número máximo de linhas por página
    const [currentPageQuinzena, setCurrentPageQuinzena] = useState(1);
    const [currentPageMeses, setCurrentPageMeses] = useState(1);

    // Função para calcular os contatos a serem exibidos
    const getPaginatedItems = (items, currentPage) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return items.slice(startIndex, startIndex + itemsPerPage);
    };

    // Funções para navegar entre páginas
    const totalPagesQuinzena = Math.ceil(contatos_quinzena.length / itemsPerPage);
    const totalPagesMeses = Math.ceil(contatos_meses.length / itemsPerPage);

    const nextPageQuinzena = () => {
        if (currentPageQuinzena < totalPagesQuinzena) {
            setCurrentPageQuinzena(currentPageQuinzena + 1);
        }
    };

    const prevPageQuinzena = () => {
        if (currentPageQuinzena > 1) {
            setCurrentPageQuinzena(currentPageQuinzena - 1);
        }
    };

    const nextPageMeses = () => {
        if (currentPageMeses < totalPagesMeses) {
            setCurrentPageMeses(currentPageMeses + 1);
        }
    };

    const prevPageMeses = () => {
        if (currentPageMeses > 1) {
            setCurrentPageMeses(currentPageMeses - 1);
        }
    };

    return (
        <Container fluid className="p-0">
            <Row className="align-items-start justify-content-between ">
                <h2 className="text-black">Crescimento por período:</h2>
                <Col md={4} className="text-left p-4 align-items-start">
                
                <p className="lead my-1"><strong>Chegaram em {formattedStartDate}:</strong></p>
                {loading ? ( <Spinner animation="border" size="lg" role="status" aria-hidden="true" className="me-1" /> ) : (
                    <h2 className="my-1 display-4">{contatos_hoje ? contatos_hoje : 0}</h2>
                    )}
                <p className="lead my-1"><strong>E no dia anterior:</strong> </p>
                {loading ? ( <Spinner animation="border" size="lg" role="status" /> ) : (
                    <h2 className="my-1 display-4">{contatos_ontem ? contatos_ontem : 0}</h2>
                    )}
                <p className="lead my-1">E nos 15 dias antes de ({formattedEndDate})</p>
                {loading ? ( <Spinner animation="border" size="lg" role="status" /> ) : (
                    <h2 className="my-1 display-4">{contatos_15_dias ? contatos_15_dias : 0}</h2>
                    )}
                </Col>

                {/* Coluna da direita para contatos_quinzena */}
                <Col md={4} className="p-4 align-items-start">
                    <h3 className="text-black text-right">Quinzena - Total adquirido</h3>
                    <Table striped bordered hover className="text-right">
                        <tbody>
                            {getPaginatedItems(contatos_quinzena, currentPageQuinzena).length > 0 ? (
                                getPaginatedItems(contatos_quinzena, currentPageQuinzena).map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-orange">| {item.periodo_quinzena} |</td>
                                        <td className="text-orange">| {item.total_cadastros} |</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="2">Nenhum dado disponível</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {/* Botões de paginação para contatos_quinzena */}
                    <div className="d-flex justify-content-start gap-2" >
                        <Button onClick={prevPageQuinzena} disabled={currentPageQuinzena === 1}>Anterior</Button>
                        <div className="vr" />
                        <Button onClick={nextPageQuinzena} disabled={currentPageQuinzena === totalPagesQuinzena}>Próxima</Button>
                    </div>
                </Col>

                {/* Coluna da direita para contatos_meses */}
                <Col md={4} className="p-4 align-items-start">
                    <h3 className="text-black text-right">| Mês | Total do mês |</h3>
                    <Table striped bordered hover className="text-right">
                        <tbody>
                            {getPaginatedItems(contatos_meses, currentPageMeses).length > 0 ? (
                                getPaginatedItems(contatos_meses, currentPageMeses).map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-orange">| {item.mes} |</td>
                                        <td className="text-orange">| {item.cadastros_por_mes} |</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="2">Nenhum dado disponível</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {/* Botões de paginação para contatos_meses */}
                    <div className="d-flex justify-content-start gap-2">
                        <Button onClick={prevPageMeses} disabled={currentPageMeses === 1}>Anterior</Button>
                        <div className="vr" />
                        <Button onClick={nextPageMeses} disabled={currentPageMeses === totalPagesMeses}>Próxima</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default GrouthMonths;