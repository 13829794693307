import { Container, Spinner } from "react-bootstrap";
import ReactApexChart from 'react-apexcharts';

const GrowthPerHour = ({ contatos_hora = [], loading = true }) => {
  // Verifique se o parâmetro contatos_hora é realmente um array
  if (!Array.isArray(contatos_hora)) {
    console.error("O valor de 'contatos_hora' não é um array.");
    return null;
  }

  // Mostra o spinner enquanto está carregando ou não há dados disponíveis
  if (loading || contatos_hora.length === 0) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center bg-orange" style={{ height: 350 }}>
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      </Container>
    );
  }

  // Mapeia os dados do objeto 'contatos_hora' para preencher o gráfico
  const seriesLine = [
    {
      name: 'Cadastros por Hora',
      data: contatos_hora.map(item => item.cadastros_por_hora),
    },
  ];

  const optionLine = {
    chart: {
      type: 'bars',
      height: 350,
      background: '#f05623',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      colors: ['#ffffff'],
      width: 2,
    },
    fill: {
      colors: ['#ffffff'],
      opacity: 0.7,
    },
    grid: {
      borderColor: '#ffffff',
    },
    xaxis: {
      labels: {
        style: {
          colors: '#ffffff',
        },
      },
      axisTicks: {
        color: '#ffffff',
      },
      axisBorder: {
        show: true,
        color: '#ffffff',
      },
      categories: contatos_hora.map(item => `${item.hora}h`), // Adiciona o "h" após a hora
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff',
        },
      },
    },
    title: {
      text: 'Horários mais movimentados',
      align: 'left',
      style: {
        fontSize: '24px',
        color: '#ffffff',
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Container fluid className="p-0 bg-orange">
      <ReactApexChart series={seriesLine} options={optionLine} type="line" height={350} />
    </Container>
  );
};

export default GrowthPerHour;
