import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Container, Spinner } from "react-bootstrap";

const ContactsEmail = ({ contatos_dominio = [], contatos_mais_abrem = [], envios_dominio = [] }) => {
  // Estados para armazenar os dados e estados de carregamento
  const [isLoadingDominio, setIsLoadingDominio] = useState(true);
  const [isLoadingMaisAbrem, setIsLoadingMaisAbrem] = useState(true);
  const [isLoadingEnviosDominio, setIsLoadingEnviosDominio] = useState(true);

  const [contatosDominio, setContatosDominio] = useState([]);
  const [contatosMaisAbrem, setContatosMaisAbrem] = useState([]);
  const [enviosDominioState, setEnviosDominioState] = useState([]); // Renomeado para evitar conflito com prop

  // Configurações de paginação
  const itemsPerPage = 6; // Número máximo de linhas por página
  const [currentPageUnknown, setCurrentPageUnknown] = useState(1);
  const [currentPageEngaged, setCurrentPageEngaged] = useState(1);

  const knownDomains = ["gmail.com", "hotmail.com", "outlook.com", "yahoo.com.br", "yahoo.com", "icloud.com", ""];

  // Atualiza os estados quando os valores das props mudarem
  useEffect(() => {
    if (contatos_dominio.length > 0) {
      setContatosDominio(contatos_dominio);
      setIsLoadingDominio(false);
    }
  }, [contatos_dominio]);

  useEffect(() => {
    if (contatos_mais_abrem.length > 0) {
      setContatosMaisAbrem(contatos_mais_abrem);
      setIsLoadingMaisAbrem(false);
    }
  }, [contatos_mais_abrem]);

  useEffect(() => {
    if (envios_dominio.length > 0) {
      setEnviosDominioState(envios_dominio);
      setIsLoadingEnviosDominio(false);
    }
  }, [envios_dominio]);

  // Filtra os domínios conhecidos e desconhecidos
  const knownContacts = enviosDominioState.filter(item => knownDomains.includes(item.dominio));
  const unknownContacts = contatosDominio.filter(item => !knownDomains.includes(item.dominio));

  // Função para calcular os itens a serem exibidos
  const getPaginatedItems = (items, currentPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  // Funções para navegação entre páginas
  const totalPagesUnknown = Math.ceil(unknownContacts.length / itemsPerPage);
  const totalPagesEngaged = Math.ceil(contatosMaisAbrem.length / itemsPerPage);

  const nextPageUnknown = () => {
    if (currentPageUnknown < totalPagesUnknown) {
      setCurrentPageUnknown(currentPageUnknown + 1);
    }
  };

  const prevPageUnknown = () => {
    if (currentPageUnknown > 1) {
      setCurrentPageUnknown(currentPageUnknown - 1);
    }
  };

  const nextPageEngaged = () => {
    if (currentPageEngaged < totalPagesEngaged) {
      setCurrentPageEngaged(currentPageEngaged + 1);
    }
  };

  const prevPageEngaged = () => {
    if (currentPageEngaged > 1) {
      setCurrentPageEngaged(currentPageEngaged - 1);
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="align-items-start justify-content-between">
        {/* Primeira coluna: Serviços conhecidos */}
        <Col md={4} className="p-4 align-items-start">
          <h3 className="text-black">Principais Serviços de Email</h3>
          {isLoadingEnviosDominio ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Carregando...</span>
              </Spinner>
            </div>
          ) : enviosDominioState.length === 0 ? (
            <div className="text-center">Dados não disponíveis</div>
          ) : (
            <Table striped bordered hover className="text-center">
              <thead>
                <tr>
                  <th>Serviço</th>
                  <th>Contatos</th>
                  <th>Média de Abertura (%)</th>
                </tr>
              </thead>
              <tbody>
                {knownContacts.length > 0 ? (
                  knownContacts.map((item, index) => (
                    <tr key={index}>
                      <td>{item.dominio}</td>
                      <td>{item.total_enviados}</td>
                      <td>{parseFloat(item.percentual_abertura).toFixed(2)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">Dados não disponíveis</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Col>

        {/* Segunda coluna: Serviços desconhecidos */}
        <Col md={4} className="p-4 align-items-start">
          <h3 className="text-black">Outros Serviços de Email</h3>
          {isLoadingDominio ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Carregando...</span>
              </Spinner>
            </div>
          ) : unknownContacts.length === 0 ? (
            <div className="text-center">Dados não disponíveis</div>
          ) : (
            <>
              <Table striped bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>Domínio</th>
                    <th>Contatos</th>
                  </tr>
                </thead>
                <tbody>
                  {getPaginatedItems(unknownContacts, currentPageUnknown).length > 0 ? (
                    getPaginatedItems(unknownContacts, currentPageUnknown).map((item, index) => (
                      <tr key={index}>
                        <td>{item.dominio}</td>
                        <td>{item.quantidade}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">Dados não disponíveis</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* Botões de paginação para serviços desconhecidos */}
              <div className="d-flex justify-content-start gap-2">
                <Button onClick={prevPageUnknown} disabled={currentPageUnknown === 1}>Anterior</Button>
                <div className="vr" />
                <Button onClick={nextPageUnknown} disabled={currentPageUnknown === totalPagesUnknown}>Próxima</Button>
              </div>
            </>
          )}
        </Col>

        {/* Terceira coluna: Contatos mais engajados */}
        <Col md={4} className="p-4 align-items-start">
          <h3 className="text-black">Top 15 Contatos Mais Engajados</h3>
          {isLoadingMaisAbrem ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Carregando...</span>
              </Spinner>
            </div>
          ) : contatosMaisAbrem.length === 0 ? (
            <div className="text-center">Dados não disponíveis</div>
          ) : (
            <>
              <Table striped bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Total Aberturas</th>
                  </tr>
                </thead>
                <tbody>
                  {getPaginatedItems(contatosMaisAbrem, currentPageEngaged).length > 0 ? (
                    getPaginatedItems(contatosMaisAbrem, currentPageEngaged).map((item, index) => (
                      <tr key={index}>
                        <td>{item.email_address}</td>
                        <td>{item.total_aberturas}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">Dados não disponíveis</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* Botões de paginação para contatos mais engajados */}
              <div className="d-flex justify-content-start gap-2">
                <Button onClick={prevPageEngaged} disabled={currentPageEngaged === 1}>Anterior</Button>
                <div className="vr" />
                <Button onClick={nextPageEngaged} disabled={currentPageEngaged === totalPagesEngaged}>Próxima</Button>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ContactsEmail;
