import React from 'react';
import { Col } from 'react-bootstrap';

const EmailQuarterBlock = ({ period, timeRange, percentage, emailsOpened }) => {
  return (
    <Col md={3} className="performance-section">
      <h2 className="text-white">{period}</h2>
      <p className="text-white">{timeRange}</p>
      <h1 className="text-black display-4">{percentage}%</h1>
      <p className="text-white">{emailsOpened}</p>
    </Col>
  );
};

export default EmailQuarterBlock;
