import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

// Mockup da função de API para obter as aplicações
const fetchApplicationsFromAPI = async (client) => {
  // Simule uma chamada de API com um atraso
  return new Promise((resolve) => {
    setTimeout(() => {
      const mockApplications = {
        client1: ['App1', 'App2', 'App3'],
        client2: ['AppA', 'AppB'],
        client3: ['AppX', 'AppY', 'AppZ'],
      };
      resolve(mockApplications[client] || []);
    }, 1000);
  });
};

const ApplicationHandler = ({ client, onApplicationChange }) => {
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState('');

  useEffect(() => {
    const fetchApplications = async () => {
      if (client) {
        const apps = await fetchApplicationsFromAPI(client);
        setApplications(apps);
      }
    };
    fetchApplications();
  }, [client]);

  const handleApplicationChange = (event) => {
    const selectedApp = event.target.value;
    setSelectedApplication(selectedApp);
    onApplicationChange(selectedApp);
  };

  if (!client) {
    return null;
  }

  return (
    <div>
      {/* <Form.Group>
        <Form.Label>Cliente: {client}</Form.Label>
      </Form.Group> */}
      {applications.length > 0 && (
        <Form.Group className="">
          {/* <Form.Label>Selecione a Aplicação</Form.Label> */}
          <Form.Select
            value={selectedApplication}
            onChange={handleApplicationChange}
          >
            <option value="" disabled>
              Selecione a Aplicação
            </option>
            {applications.map((application, index) => (
              <option key={index} value={application}>
                {application}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
    </div>
  );
};

export default ApplicationHandler;
