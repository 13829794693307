import React from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateRangePicker.css'; // Importe seu arquivo CSS personalizado

const DateRangePicker = ({
  onFilter,
  onDateChange,
  startDate,
  endDate,
  loading, // Recebe o estado 'loading' do componente pai
}) => {

  const handleDateChange = (date, isStartDate) => {
    if (isStartDate) {
      onDateChange(date, endDate);
    } else {
      onDateChange(startDate, date);
    }
  };

  const formatDisplayDate = (date) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Row className="align-items-center justify-content-center g-2 flex-nowrap" style={{ width: '100%' }}>
      {/* Primeira Coluna: Label "Filtrar de:" */}
      <Col xs={2} className="d-flex align-items-center">
        <p className="mb-0 date-label">Filtrar de:</p>
      </Col>

      {/* Segunda Coluna: Date Picker de Início */}
      <Col xs={3} style={{ flex: '0 0 29.17%', maxWidth: '29.17%' }}>
        <ReactDatePicker
          selected={startDate}
          onChange={(date) => handleDateChange(date, true)}
          className="form-control date-picker-input"
          placeholderText="Data de Início"
          dateFormat="dd/MM/yyyy"
          customInput={
            <input
              className="form-control date-picker-input"
              value={formatDisplayDate(startDate)}
              readOnly
            />
          }
        />
      </Col>

      {/* Terceira Coluna: Label "até:" */}
      <Col xs={1} className="d-flex align-items-center justify-content-center">
        <p className="mb-0 date-label">até:</p>
      </Col>

      {/* Quarta Coluna: Date Picker de Fim */}
      <Col xs={3} style={{ flex: '0 0 29.17%', maxWidth: '29.17%' }}>
        <ReactDatePicker
          selected={endDate}
          onChange={(date) => handleDateChange(date, false)}
          className="form-control date-picker-input"
          placeholderText="Data de Fim"
          dateFormat="dd/MM/yyyy"
          customInput={
            <input
              className="form-control date-picker-input"
              value={formatDisplayDate(endDate)}
              readOnly
            />
          }
        />
      </Col>

{/* Quinta Coluna: Botão "Filtrar" */}
<Col xs={1} className="d-flex align-items-center">
  <Button
    onClick={onFilter}
    className="btn btn-primary filter-button"
    size="sm"
    disabled={loading} // Desabilita o botão durante o carregamento
  >
    {loading ? (
      <span className="d-flex align-items-start">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="me-1"
        />
        <span>...</span>
      </span>
    ) : (
      'Filtrar'
    )}
  </Button>
</Col>

    </Row>
  );
};

export default DateRangePicker;
