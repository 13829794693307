import React from "react";
import { Col, Row, Container, Placeholder, Spinner } from "react-bootstrap";

const GrowthTotal = ({ startDate, endDate, TotalContacts = 0, loading = false }) => {
  const formattedStartDate =
    startDate instanceof Date ? startDate.toLocaleDateString("pt-BR") : startDate;
  const formattedEndDate =
    endDate instanceof Date ? endDate.toLocaleDateString("pt-BR") : endDate;

  return (
    <Container fluid className="p-0 mt-3 mb-5">
      <Row className="align-items-stretch">
        {/* Primeira coluna */}
        <Col xs={6} md={6} className="text-end p-4 justify-content-end">
          <h2 className="text-orange display-7 mt-3">
            No período de <br />
            {formattedStartDate} até {formattedEndDate}
            <br /> adquirimos:
          </h2>
        </Col>

        {/* Segunda coluna */}
        <Col
          xs={6}
          md={6}
          className="text-start bg-orange d-flex justify-content-start align-items-center p-4"
        >
          <div>
                {loading ? (
      <span className="d-flex align-items-start">
        <Spinner
          as="span"
          animation="border"
          size="xl"
          role="status"
          aria-hidden="true"
          className="me-1"
        />
        <span>...</span>
      </span>
    ) : (
      <div>
        <h3 className="display-1 text-white">{TotalContacts ? TotalContacts.toLocaleString() : "--"}</h3>

        </div>
    )}
        <h2 className="text-white">leads +</h2>
          </div>
        </Col>
      </Row>

      {/* Texto do parágrafo */}
      <p className="small mt-2 text-center">
        * O número pode não representar o total de leads capturados durante toda a vida do
        contrato, pois realizamos uma limpeza periódica, removendo leads inativos.
      </p>
    </Container>
  );
};

export default GrowthTotal;
