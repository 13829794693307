import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from './AuthContext';

const RoutesGate = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, checkToken } = useContext(AuthContext); // Certifique-se de que checkToken está sendo obtido corretamente

  // Verifica a autenticação do token
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken && !isAuthenticated) {
      console.log('Token encontrado no localStorage, verificando validade...');
      checkToken(); // Chama a função checkToken para verificar a validade do token
    }
  }, [isAuthenticated, checkToken]);

  if (!isAuthenticated) {
    console.log('Não está autenticado, redirecionando para o login');
    // Guarda a última tentativa de acesso.
    const attemptedPath = `${location.pathname}${location.search}`;
    sessionStorage.setItem('redirectAfterLogin', attemptedPath);

    return <Navigate to="/login" replace />; // Redireciona para login
  }

  return children; // Se autenticado, renderiza os children
};

export default RoutesGate;
