import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserAvatar from '../UserAvatar/UserAvatar';
import AuthContext from '../../contexts/AuthContext'; // Ajuste o caminho conforme necessário

const ProfileMenu = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
  const userName = userProfile.title || '::User Name::';
  const userRole = userProfile.role_name || '::Role Name::';

  const handleLogout = (e) => {
      e.preventDefault();
      logout();
  };

  return (
    <div className="dropdown-menu-body">
      <UserAvatar size="xl" status="online" imgSize={64} margin="mb-3" />
      <h5 className="mb-1 text-dark fw-semibold">{userName}</h5>
      <p className="fs-sm text-secondary">{userRole}</p>
      <nav className="nav">
        <Link to="/user/profile"><i className="ri-profile-line"></i> View Profile</Link>
      </nav>
      <hr />
      <nav className="nav">
        <Link to="/help"><i className="ri-question-line"></i> Help Center</Link>
        <Link to="/user/privacy"><i className="ri-lock-line"></i> Privacy Settings</Link>
        <Link to="/user/settings"><i className="ri-user-settings-line"></i> Account Settings</Link>
        <Link to="#" onClick={handleLogout}><i className="ri-logout-box-r-line"></i> Log Out</Link>
      </nav>
    </div>
  );
};

export default ProfileMenu;
