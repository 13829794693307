import React, { createContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clogin as apiLogin, ccheckToken as apiCheckToken, clogout as apiLogout } from '../api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const storedToken = localStorage.getItem('token') || null;
  const [isAuthenticated, setIsAuthenticated] = useState(!!storedToken);
  const [token, setToken] = useState(storedToken);

  const logout = useCallback(() => {
    apiLogout();
    setIsAuthenticated(false);
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('accounts');
    sessionStorage.removeItem('session_valid');
    navigate('/login');
  }, [navigate]);

  const login = useCallback(async (username, password) => {
    try {
      const response = await apiLogin(username, password);
      const token = response.token || response.access_token;
      if (token) {
        localStorage.setItem('token', token); 
        setToken(token); 
        setIsAuthenticated(true);
        const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin');
        if (redirectAfterLogin) {
          navigate(redirectAfterLogin);
          sessionStorage.removeItem('redirectAfterLogin'); // Remove a URL armazenada após redirecionar
        } else {
          navigate('/'); // Redireciona para a página inicial por padrão
        }
        return true;
      } else {
        console.error('Token não recebido');
        return false;
      }
    } catch (error) {
      console.error('Erro durante o login:', error);
      return false;
    }
  }, [navigate]);

  const checkToken = useCallback(async () => {
    if (token) {
      try {
        const isValid = await apiCheckToken(token);
        if (isValid) {
          setIsAuthenticated(true);
        } else {
          logout(); // Se o token for inválido, realiza o logout
        }
      } catch (error) {
        console.error('Erro ao validar o token:', error);
        logout(); // Em caso de erro, realiza o logout
      }
    }
  }, [token, logout]);

  useEffect(() => {
    checkToken(); // Verifica o token ao montar o componente
  }, [checkToken]);

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      setIsAuthenticated,
      login,
      logout,
      token,
      checkToken, // Aqui estamos exportando a função corretamente
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
