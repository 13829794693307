import { GetGravatar } from '../../services/GetGravatar'; 
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import './UserAvatar.css';

export default function UserAvatar({
  imgSize = 64,
  size = null,
  shape = null,
  status = null,
  margin = null,
}) {
  const [avatar, setAvatar] = useState({ type: 'default' });

  useEffect(() => {
    async function fetchAvatar() {
      const userAvatar = await GetGravatar(imgSize);
      setAvatar(userAvatar);
    }
    fetchAvatar();
  }, [imgSize]);

  const avatarClass = `avatar ${size ? "avatar-" + size : ""} ${shape ? "avatar-" + shape : ""} ${status ? status : ""} ${margin ? margin : ""}`;

  if (avatar.type === 'gravatar' || avatar.type === 'local') {
    return (
      <div className={avatarClass}>
        <img src={avatar.url || ''} alt="User Avatar" />
      </div>
    );
  }

  return (
    <div className={avatarClass} style={{ backgroundColor: 'var(--bs-primary)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <i className={`ri-user-3-line icon-${size}`} style={{ color: 'var(--bs-light)' }}></i>
    </div>
  );
}

UserAvatar.propTypes = {
  imgSize: PropTypes.number,
  size: PropTypes.string,
  shape: PropTypes.string,
  status: PropTypes.string,
};
