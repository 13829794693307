import React from "react";

// Myroutes
import Dashboard from "../dashboard/Dashboard";
import Management from "../dashboard/Management";
import Mautic from "../dashboard/Mautics";
import N8n from "../dashboard/N8n";
import Wordpress from "../dashboard/Wordpress";
import Outras from "../dashboard/Outras";
import GetAccount from "../dashboard/GetAccount";

const protectedRoutes = [
  { path: "report", element: <Management /> },
  { path: "report/mautic", element: <Mautic /> },
  { path: "report/n8n", element: <N8n /> },
  { path: "report/wordpress", element: <Wordpress /> },
  { path: "report/outras", element: <Outras /> },
  { path: "dashboard", element: <Dashboard /> },
  { path: "getaccount", element: <GetAccount /> }
]

export default protectedRoutes;