// src/api/auth.js

import CCSApi from '../services/ccsApi'; // Importa a classe CCSApi

// Define uma instância da classe CCSApi
const ccsApi = new CCSApi();

// Função de logout completa que limpa todos os dados de sessão
export const logout = () => {
  console.log('Executando logout e limpando dados de sessão...');
  
  // Limpa o token e todos os outros dados relevantes do localStorage
  localStorage.removeItem('token'); // Remove o token de autenticação
  localStorage.removeItem('userProfile'); // Exemplo: Remove o perfil do usuário armazenado
  localStorage.removeItem('selectedAccountId'); // Exemplo: Remove o ID da conta selecionada
  localStorage.removeItem('accounts'); // Exemplo: Remove informações de contas

  // Outras ações de logout podem ser adicionadas aqui, como redirecionar para a página de login
  // window.location.href = '/login'; // Redireciona para a página de login após logout
};

// Função para realizar login
export const login = async (email, password) => {
  try {
    const response = await ccsApi.post('/auth/login', { email, password });
    if (response.token || response.access_token) {
      // localStorage.setItem('token', response.token); // Armazena o token de autenticação
      // localStorage.setItem('access_token', response.access_token);
      return response;
    } else {
      throw new Error('Token not received');
    }
  } catch (error) {
    console.error('Erro durante o login:', error);
    throw error;
  }
};

// Função para verificar a validade do token
export const checkToken = async (token) => {
  try {
    const response = await ccsApi.get('/auth/check', token);
    console.log(response)
    // Verifica se o status é 200 e se o conteúdo da resposta contém "token": "valid"
    if (response && response.token === "valid") {
      console.log('TOKEN válido');
      return true;
    } else {
      console.log('TOKEN não é válido');
      return false;
    }
  } catch (error) {
    console.error('Erro durante a verificação do token:', error);
    console.log('erro na hora de consultar');
    return false;
  }
};
