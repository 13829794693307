import React from 'react';
import { Row } from 'react-bootstrap';
import StatCard from './StatCard';

const StatCards = ({ cardsData = [], loading = false }) => {
  return (
    <Row className="g-3">
      {cardsData.map((card, index) => (
        <StatCard
          key={index}
          label={card.label}
          icon={card.icon}
          value={card.value}
          percent={card.percent}
          status={card.status}
          bottom_line={card.bottom_line}
          backgroundColor={card.backgroundColor}
          iconColor={card.iconColor}
          isFirst={index === 0}
          loading={loading} // Passando o estado de loading para cada card
        />
      ))}
    </Row>
  );
};

export default StatCards;
